import React from "react"
import "./src/global.less"

import { BookingFormProvider } from "./src/context/booking-form"

export const wrapRootElement = ({ element }) => (
  <BookingFormProvider>{element}</BookingFormProvider>
)

export const onRouteUpdate = () => {
  window.analytics && window.analytics.page();
}
