import React from "react"
import Shoot from "../models/shoot.model"
import { Scene } from "../models/scene.model"
import { Package } from "../models/package.model"
import { Payment } from "../models/payment.model"

export interface BookingFormContext {
  shoot: Shoot,
  promocode: string,
  turnOffLoader: Function,
  purpose: string
  scene_list: Scene[];
  scene_list_mapped: { [key: string]: Scene[] };
}

const defaultState: BookingFormContext = {
  turnOffLoader: null,
  shoot: {
    id: null,
    payment: {} as Payment,
    addons: [],
    address: null,
    scene: {} as Scene,
    package: {} as Package,
    city: null,
    latitude: null,
    additional_details: null,
    longitude: null,
    postcode: null,
    starts_at: null,
    user: {
      first_name: null,
      last_name: null,
      email: null,
      phone: null
    }
  },
  promocode: null,
  purpose: null,
  scene_list:  [],
  scene_list_mapped: {},
}

const BookingFormContext = React.createContext(defaultState)

class BookingFormProvider extends React.Component {
  state: BookingFormContext = defaultState

  componentDidMount() {
  }

  render() {
    const { children } = this.props
    return (
      <BookingFormContext.Provider
        value={this.state}
      >
        {children}
      </BookingFormContext.Provider>
    )
  }
}

export default BookingFormContext

export { BookingFormProvider }